// $primary: #a51616;
$primary: #0048b2; /* MAIN COLOR */
$secondary: #ffa900; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
} 

* {
    margin: 0;
    padding: 0;
}
body {
    font-family: 'Overlock', cursive;
    overflow-y: scroll;
    overflow-x: hidden;
	background: $wht;
}
p {
    
    font-size: 14px;
}

a {color: $primary;}


.flash {
	display:none;
}

h1 , h3 {font-family: 'Oleo Script', cursive;
	color: #fff;


}


.container-fluid {margin-top: 0px;
				  padding-right: 0px;
				  padding-left: 0px;}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 350px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}

}



/* =========== Navigation ============ */

.navbar.navbar-default {background-image: url('../img/nav-bg.png');
                         background-repeat: repeat-x;
                         height: 119px;
                         z-index: 1000;
                         background-color: transparent;
                         border: 0px;

}

.navbar-default .navbar-collapse {border-bottom: 0px;}

.navbar-brand {margin-top: 0px;
			   padding-top: 0px;
               }

.navbar-right {margin-top: 25px;}

.navbar-default .navbar-nav > li > a {font-size: 1.5em;
                                      font-family: 'Overlock', cursive;
                                      font-weight: bold;
                                      color: #004B82;
                                      background-color: white;
                                        border-bottom: 0px !important;
}

.navbar-default .navbar-nav > li > a:visited { color: #004B82;
                                             background-color: #fff;

}

.navbar-default .navbar-nav > li > a:hover { color: #ffa900;
                                             background-color: #fff;

}

.navbar-default .navbar-nav > li > a:focus { color: #004B82;
                                             background-color: #fff;

}

.navbar-default .navbar-collapse {border: 0px;}

/* =========== Header ============ */
.header {
    background-image: url("../img/banner.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 100% 15%;
    z-index: -1000;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover; 
    background-color: #fff;
    margin-top: -50px;

        @media (max-width: 767px) {
                margin-top: -25px;
  }

        @media (max-width: 1024px) {
        margin-top: -25px;
        background-attachment: scroll;
        background-position: 75% 50%;

        }

}


.header-overlay {
    background-color: rgba(31, 31, 31, 0.75);
    padding: 200px 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      border: 0px !important;
    
    @media (max-width: 767px) {
            padding: 75px 0px;
    }


@media (min-width: 1198px) and (orientation: landscape) {
padding: 75px 0px; 
margin-bottom: 0px;

}

}


.logo {width: 100%;
    @media (max-width: 767px) {
        max-width: 175px;
        padding-top: 20px;
        padding-left: 5px;
    }
}

.logo-large {top: 0px;   
    @media (max-width: 767px) {
        top: 25px;
    }   

    @media (max-width: 1199px){
                color: #fff;
                font-size: 1.15em;
    }
}

.logo-large p { color: #fff;
                font-size: 1.5em;
                line-height: 45px;
                margin-bottom: 45px;
                padding-left: 10px;
                
                @media (max-width: 767px) {
                        padding-top: 45px;
                        text-align: center;
    }  
}

@media (max-width: 1199px) {


.logo-large p { color: #fff;
                font-size: 1.15em;
                line-height: 40px;
} 


}

/* =========== Buttons ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color: #ffa900;
    border-color: #ffa900;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 3px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
}
.btn-download:hover {
    background-color: #0048b2;
    color: #ffa900;

}
.btn-tour,
.btn-tour:focus,
.btn-tour:active,
.btn-tour:visited {
    color:$primary;
    margin-left: 20px;
    text-transform: uppercase;
    font-size: 19px;
    font-weight: 500;
    background: transparent;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
    
}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*============== Middle Call To Action ==================== */


.middle-cta {
	padding: 50px;
	background: #004B82;
    text-align: center;
    border-top: 5px solid #ffa900;
    border-bottom: 5px solid #ffa900;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
@media (max-width: 1024px) {
        padding: 50px 0px;
    }
}

.middle-cta h1 {
    position: relative;
    text-align: center;
    color: #ffa900;
    font-size: 5em;
    font-weight: bold;

@media (max-width: 767px) {
    font-size: 2.5em;
 }
}
.middle-cta h1:after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 50%;
    width: 60px;
    height: 1px;
    background-color: #ffa900;
    margin-left: -30px;
}


.middle-cta p {
    color: #fff;
    font-size: 20px;
    line-height: 45px;
    font-weight: 300;
    text-align: left;
    margin-top: 45px;
    padding-bottom: 45px;
@media (max-width: 767px) {
    text-align: center;

    }
}
/* ========= Features ============= */

.features {
	padding: 35px 0px;
    text-align: center;

        @media (max-width: 1199px) {
            padding: 5px 0px;
        }
}

.features h1 {
    position: relative;
    text-align: center;
    color: #004B82;
    font-size: 5em;
    font-weight: bold;
@media (max-width: 767px) {
        font-size: 2.75em;
    }
@media (min-width: 1024px) {
        font-size: 4em;
    }
}
.features h1:after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 50%;
    width: 60px;
    height: 1px;
    background-color: #004B82;
    margin-left: -30px;
}

.features-right h3 {
    text-align: left;
    color: #004B82;
    font-size: 2em;
    font-weight: bold;

    @media (max-width: 767px) {
        text-align: center;
        padding-top: 50px;
 }
     @media (max-width: 1024px) {
        text-align: center;
 }
 @media (min-width: 1024px) {
        text-align: left;
    }

@media (min-width: 1024px) and (orientation: landscape) {

font-size: 1.75em;
}

}
.features-right p {
    color: #004B82;
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
    text-align: left;
    margin-top: 15px;
    padding-bottom: 0px;
@media (max-width: 1024px) {
        text-align: center;
    }
@media (min-width: 1024px) {
        text-align: left;
    }

@media (min-width: 1024px) and (orientation: landscape) {

font-size: 18px;
}

}
.features-left h3 {
    text-align: right;
    color: #004B82;
    font-size: 2em;
    font-weight: bold;
@media (max-width: 1024px) {
              text-align: center;
              padding-top: 25px;
            }
@media (min-width: 1024px) {
        text-align: right;
    }
@media (max-width: 1199px) {
            padding-top: 0px;

        }

@media (min-width: 1024px) and (orientation: landscape) {

font-size: 1.75em;
}

}

.features-left p {
    color: #004B82;
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
    text-align: right;
    margin-top: 15px;
    padding-bottom: 0px;
    
@media (max-width: 1024px) {
        text-align: center;
    }
@media (min-width: 1024px) {
        text-align: right;
    }

@media (min-width: 1024px) and (orientation: landscape) {

font-size: 18px;
}

}

.feature-img {position:relative;
              top: 88px;
              
     @media (max-width: 768px) {
            top: 0px !important;
             display: block;
             margin: 0 auto;
             max-width: 392px;
             margin-bottom: 15px;
     }
     @media (max-width: 1199px) {
            top: 173px;
             max-width: 392px;
             display: block;
             margin: 0 auto;
     }
   @media (max-width: 991px) {
        position: static;
            display: block;
             margin: 0 auto;
             max-width: 392px;
        
   }
@media (min-width: 1024px) and (orientation: landscape) {
 
top: 139px;

}

@media (min-width: 1199px) and (orientation: landscape) {
 
top: 51px;

}
                
}


/* ========= Lower CTA ============= */

.lower-cta {padding: 50px 0px 50px 0px;
            text-align: center;
            position: relative;
    
    @media (max-width: 1024px) {
        padding: 10px;

    }

}

.lower-cta h1 {
    position: relative;
    text-align: center;
    color: #004B82;
    font-size: 2em;
    font-weight: bold;

    @media (max-width: 1199px) {
        font-size: 2em;

    }
}

.lower-cta h1:after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 50%;
    width: 60px;
    height: 1px;
    background-color: #004B82;
    margin-left: -30px;
}


.lower-cta p {
    color: #004B82;
    font-size: 20px;
    line-height: 45px;
    font-weight: 300;
    text-align: left;
    margin-top: 45px;
    padding-bottom: 20px;

    @media (max-width: 1024px) {
        
        line-height: 35px;
    }   


}


/* .lower-cta img {

@media (max-width: 768px) {
            top: 0px !important;
            display: block;
             margin: 0 auto;
             max-width: 285px;
             margin-bottom: 15px;
    }
@media (max-width: 1024px) {
            top: 124px;
             max-width: 100%;
    }

@media (max-width: 1199px) {
            top: 150px !important;
             max-width: 100%;
    }
}
*/

.couple {
    top: 50px;
    display: block;
    margin: 0 auto;
    
    
    @media (max-width: 768px) {
     top: 0px !important;   
    }
   
   @media (max-width: 991px) {
            position: static;
            display: block;
            margin: 0 auto;
            max-width: 392px;
            width: 100%;
            top: 99px;
   }

@media (max-width: 1025px) and (orientation: landscape) {
 
top: 19px !important;

}

@media (max-width: 1199px) and (orientation: landscape) {
 
top: 99px;

}

}


.top-pad h3 {color: #004B82;}

.top-pad h2 {color: #004B82;}

.top-pad > ol {padding-left: 75px;}